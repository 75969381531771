<template>
  <v-container>
    <v-row align="center">
      <v-col>
        <v-form>
          <v-container>
            <v-col>
              <v-row v-for="(filter, index) in filters" :key="index">
                <v-col cols="8">
                  <v-autocomplete v-model="filter.ench" auto-select-first :items="enchants"
                    @change="changed"></v-autocomplete>
                </v-col>
                <v-col cols=" 2">
                  <v-text-field v-model="filter.value" label="Level" required align="right"></v-text-field>
                </v-col>
              </v-row>
              <v-btn align="left" class="mr-4" @click="searchForMystic" :loading="isLoading">
                Search
              </v-btn>
            </v-col>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
    <v-row v-if="items.length > 0" align="stretch" class="d-flex justify-center align-center">
      <v-card outlined align="left" v-for="item in items" :key="item._id" @click="ownershipHistory(item)">
        <v-card-text>
          <p v-html="translateString(item.name)"></p>
          <p>
            Owner:
            <a style="text-decoration: none; color: white"
              :href="'https://pitpanda.rocks/players/' + item.ownerusername" target="_blank">{{ item.ownerusername
              }}</a>
          </p>
          <p>Last Seen: {{ translateDate(item.lastsave) }}</p>
          <p v-for="(str, index) in item.lore" :key="index" v-html="translateString(str)"></p>
        </v-card-text>
      </v-card>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="login" persistent justify="center" width="400">
        <v-card width="380">
          <v-card-title>
            <span class="text-h5">Beta Access</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Password*" type="password" v-model="password" required></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!--  <v-btn color="blue darken-1" text @click="$router.push('/')">
              Close
            </v-btn> -->
            <v-btn color="blue darken-1" text @click="auth()"> Login </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" scrollable max-width="600px">
        <v-card>
          <v-card-title>Ownership History</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 300px">
            <v-card class="mx-auto" outlined v-for="(iOwner, index) in ownerHistory" :key="index">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    <a :href="'https://namemc.com/profile/' + iOwner.username" target="_blank"
                      style="text-decoration: none; color: white">
                      {{ iOwner.username }}
                    </a>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                      parseDate(iOwner.time)
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar tile size="40" color="grey">
                  <img :src="'https://crafatar.com/avatars/' + iOwner.uuid" :alt="iOwner.username" />
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="loading" hide-overlay persistent width="400">
        <v-card style="margin: 0px; padding: 9px" color="primary" dark>
          <v-card-text>
            Please stand by
            <p></p>
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>


<script>
//import request from 'request';
import fetch from "node-fetch";
export default {
  data() {
    return {
      isLoading: false,
      login: false,
      password: "",
      loading: false,
      dialog: false,
      filters: [
        {
          ench: "",
          value: "",
        },
      ],
      owner: "",
      ownerHistory: [],
      items: [],
      colorCodes: {
        0: "#000000",
        1: "#0000AA",
        2: "#00AA00",
        3: "#00AAAA",
        4: "#AA0000",
        5: "#AA00AA",
        6: "#FFAA00",
        7: "#999999",
        8: "#3f3f3f",
        9: "#5555FF",
        a: "#55FF55",
        b: "#55FFFF",
        c: "#FF5555",
        d: "#FF55FF",
        e: "#FFFF55",
        f: "#FFFFFF",
      },
      enchants: [
        "Regularity",
        "New Deal",
        '"Not" Gladiator',
        "Mirror",
        "RARE! Instaboom",
        "Do it like the French",
        "Eggs",
        "Heigh-Ho",
        "Think of the People",
        "RARE! Paparazzi",
        "RARE! Trash Panda",
        "Gold Bump",
        "Moctezuma",
        "RARE! Executioner",
        "Gold Boost",
        "Golden Heart",
        "Self-checkout",
        "RARE! Double-jump",
        "Combo: Breaching Charge",
        "Lodbrok",
        "Brakes!",
        "Beat the Spammers",
        "Sweaty",
        "Lifesteal",
        "XP Boost",
        "Sierra",
        "Pitpocket",
        "Somber",
        "RARE! Combo: Venom",
        "Misery",
        "Spite",
        "Combo: Heal",
        "Combo: Swift",
        "Critically Rich",
        "Diamond Stomp",
        "Fancy Raider",
        "Crush",
        "Bruiser",
        "Pants Radar",
        "Shark",
        "Gold and Boosted",
        "Pain Focus",
        "Grasshopper",
        "Diamond Allergy",
        "Excess",
        "Ring Armor",
        "Strike Gold",
        "TNT",
        "Protection",
        "Negotiator",
        "David and Goliath",
        "Fletching",
        "What doesn't kill you",
        "Respawn: Absorption",
        "Steaks",
        "RARE! Gamble!",
        "Duelist",
        "Club Rod",
        "Portable Pond",
        "Pebble",
        "XP Bump",
        "Bullet Time",
        "Rogue",
        "Peroxide",
        "Billy",
        "RARE! Solitude",
        "Combo: Damage",
        "Speedy Kill",
        "RARE! Combo: Stun",
        "Jumpspammer",
        "RARE! Devil Chicks!",
        "Respawn: Resistance",
        "Gotta go fast",
        "Boo-boo",
        "Wasp",
        "Pin down",
        "King Buster",
        "RARE! The Punch",
        "Counter-Offensive",
        "RARE! Phoenix",
        "Combo: XP",
        "RARE! Hemorrhage",
        "Punisher",
        "RARE! Healer",
        "Tough Crew",
        "RARE! Pullbow",
        "RARE! Telebow",
        "RARE! Billionaire",
        "Sniper",
        "RARE! Volley",
        "RARE! Knockback",
        "Royalty",
        "RARE! Stereo",
        "Mind Assault",
        "Berserker",
        "Cricket",
        "Revitalize",
        "Bottomless Quiver",
        "Grandmaster",
        "First Shot",
        "Guts",
        "Revengeance",
        "Faster than their shadow",
        "Spammer and Proud",
        "Parasite",
        "Sprint Drain",
        "Bounty Reaper",
        "Counter-Janitor",
        "RARE! Gomraw's Heart",
        "RARE! Wolf Pack",
        "Hunt the Hunter",
        "Hearts",
        "Rodback",
        "RARE! Combo: Perun's Wrath",
        "RARE! Explosive",
        "Arrow Armory",
        "RARE! Escape Pod",
        "McSwimmer",
        "RARE! Mega Longbow",
        "Mixed Combat",
        "Chipping",
        "RARE! Speedy Hit",
        "Danger Close",
        "Sharp",
        "RARE! Assassin",
        "Last Stand",
        "RARE! Singularity",
        "Electrolytes",
        "Purple Gold",
        "Pit MBA",
        "Grim Reaper",
        "RARE! Divine Miracle",
        "RARE! Pit Blob",
        "Creative",
        "Prick",
        "Worm",
        "Critically Funky",
        "RARE! Lucky Shot",
        "RARE! Martyrdom",
        "RARE! Snowballs",
        "Push comes to shove",
        "RARE! True Shot",
        "Hedge Fund",
        "Aegis",
        "RARE! Heartripper",
        "Hidden Jewel",
        "Hidden Jewel",
        "RARE! Snowmen Army",
        "Fractional Reserve",
        "Needless Suffering",
        "Unite",
        "RARE! Robinhood",
        "Lycanthropy",
        "Sanguisuge",
        "Trophy",
        "Luck of the Pond",
        "RARE! Nostalgia",
        "RARE! Golden Handcuffs",
        "Guardian",
        "Evil Within",
        "RARE! Retro-Gravity Microcosm",
        "Tier",
        "Really Toxic",
        "Brakes"
      ],
    };
  },
  methods: {
    auth: async function () {

      const body = {
        'username': 'omi',
        'password': this.password,
      }

      const response = await fetch("https://omi.systems/login", {
        method: "post",
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();

      console.log(data);
      this.login = false;
    },
    searchForMystic: async function () {
      let queryString = "";

      /* let level1 = this.searchLevel1;
      let level2 = this.searchLevel2;
      let level3 = this.searchLevel3;

      if (this.searchLevel1 == "") {
        level1 = "0+";
      }
      if (this.searchLevel2 == "") {
        level2 = "0+";
      }
      if (this.searchLevel3 == "") {
        level3 = "0+";
      }

      if (this.searchEnchant1 != "") {
        queryString += translate(this.searchEnchant1) + level1;
      }
      if (this.searchEnchant2 != "") {
        queryString += "," + translate(this.searchEnchant2) + level2;
      }
      if (this.searchEnchant3 != "") {
        queryString += "," + translate(this.searchEnchant3) + level3;
      } */

      for (let i = 0; i < this.filters.length; i++) {
        if (this.filters[i].ench != null && this.filters[i].ench.length > 0) {
          queryString += translate(this.filters[i].ench);
        } else {
          continue;
        }

        if (
          this.filters[i].value != null &&
          this.filters[i].value != "" &&
          !isNaN(this.filters[i].value)
        ) {
          queryString += this.filters[i].value;
        } else {
          queryString += "0+";
        }
        queryString += ",";
      }

      queryString = queryString.substring(0, queryString.length - 1);

      console.log(queryString);

      if (queryString.length == 0) {
        alert("Please enter at least one enchant");
      }
      this.isLoading = true;
      const response = await fetch("https://omi.systems/search/" + queryString);

      this.items = await response.json();
      this.isLoading = false;
      /* console.log(this.items); */
    },
    changed: function () {
      //console.log(this.filters)
      for (let i = 0; i < this.filters.length - 1; i++) {
        // If one of the middle values has been deleted reset the search.

        if (this.filters[i].ench == null) {
          // We reset the search with only the ones that are not null.
          let newArrray = [];
          for (let n = 0; n < this.filters.length; n++) {
            if (this.filters[n].ench != null) {
              newArrray.push(this.filters[n]);
            }
          }
          this.filters = newArrray;
          return;
        }
      }

      // None of the middle values are empty, so we can add a new one.

      if (this.filters[this.filters.length - 1].ench != "")
        this.filters.push({
          value: "",
          ench: "",
        });
    },
    translateString: function (str) {
      if (str.length == 0) {
        return "";
      }
      let responseString = "";
      let firstColor = true;
      let nextColor = false;
      for (let letter of str) {
        //console.log(letter)
        if (letter === "§") {
          nextColor = true;
          continue;
        }
        if (nextColor) {
          nextColor = false;
          if (!firstColor) {
            responseString += "</span>";
          } else {
            firstColor = false;
          }

          responseString +=
            '<span style="color: ' + this.colorCodes[letter] + ';">';
          continue;
        }
        responseString += letter;
      }
      responseString += "</span>";

      return responseString;
    },
    translateDate: function (timestamp) {
      var a = new Date(timestamp * 1000);
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();

      var time =
        date +
        " " +
        month +
        " " +
        year +
        " " +
        hour.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        ":" +
        min.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
      return time;
    },
    ownershipHistory: async function (item) {
      if (item.nonce == 9 || item.nonce == 6) return;
      this.loading = true;
      const response = await fetch("https://omi.systems/owner/" + item.nonce);
      const json = await response.json();
      console.log(json);

      this.ownerHistory = json.reverse();

      this.loading = false;
      this.dialog = true;
    },
    parseDate: function (str) {
      return new Date(str).toLocaleDateString();
    },
  },
  mounted: async function () {
    const response = await fetch("https://omi.systems/check");
    const data = await response.json();

    this.login = !data.status;
  },
};

function translate(enchant) {
  const dictionary = [
    { name: "do_it_like_the_french", value: "Do it like the French"},
    { name: "regularity", value: "Regularity" },
    { name: "new_deal", value: "New Deal" },
    { name: "less_damage_nearby_players", value: '"Not" Gladiator' },
    { name: "immune_true_damage", value: "Mirror" },
    { name: "instaboom_tnt", value: "RARE! Instaboom" },
    { name: "eggs", value: "Eggs" },
    { name: "heigh_ho", value: "Heigh-ho" },
    { name: "paparazzi", value: "RARE! Paparazzi" },
    { name: "trash_panda", value: "RARE! Trash Panda" },
    { name: "gold_per_kill", value: "Gold Bump" },
    { name: "gold_strictly_kills", value: "Moctezuma" },
    { name: "melee_execute", value: "RARE! Executioner" },
    { name: "gold_boost", value: "Gold Boost" },
    { name: "absorption_on_kill", value: "Golden Heart" },
    { name: "max_bounty_self_claim", value: "Self-checkout" },
    { name: "double_jump", value: "RARE! Double-jump" },
    { name: "increase_armor_drops", value: "Lodbrok" },
    { name: "melee_damage_vs_bows", value: "Beat the Spammers" },
    { name: "streak_xp", value: "Sweaty" },
    { name: "melee_heal_on_hit", value: "Lifesteal" },
    { name: "xp_boost", value: "XP Boost" },
    { name: "gold_per_diamond_piece", value: "Sierra" },
    { name: "pickpocket", value: "Pitpocket" },
    { name: "somber", value: "Somber" },
    { name: "venom", value: "RARE! Combo: Venom" },
    { name: "misery", value: "Misery" },
    { name: "spite", value: "Spite" },
    { name: "melee_combo_heal", value: "Combo: Heal" },
    { name: "melee_combo_speed", value: "Combo: Swift" },
    { name: "gold_per_crit", value: "Critically Rich" },
    { name: "melee_damage_vs_diamond", value: "Diamond Stomp" },
    { name: "melee_damage_vs_leather", value: "Fancy Raider" },
    { name: "melee_weakness", value: "Crush" },
    { name: "increased_blocking", value: "Bruiser" },
    { name: "breaching_charge", value:"Combo: Breaching Charge"},
    { name: "pants_radar", value: "Pants Radar" },
    { name: "melee_damage_when_close_low_players", value: "Shark" },
    { name: "melee_damage_when_absorption", value: "Gold and Boosted" },
    { name: "melee_damage_when_low", value: "Pain Focus" },
    { name: "melee_damage_when_on_grass", value: "Grasshopper" },
    { name: "less_damage_vs_diamond_weapons", value: "Diamond Allergy" },
    { name: "overheal_enchant", value: "Excess" },
    { name: "less_damage_from_arrows", value: "Ring Armor" },
    { name: "gold_per_hit", value: "Strike Gold" },
    { name: "tnt", value: "TNT" },
    { name: "damage_reduction", value: "Protection" },
    { name: "contract_rewards", value: "Negotiator" },
    { name: "less_damage_vs_bounties", value: "David and Goliath" },
    { name: "bow_damage", value: "Fletching" },
    { name: "heal_on_shoot_self", value: "What doesn't kill you" },
    { name: "respawn_with_absorption", value: "Respawn: Absorption" },
    { name: "steaks_on_kill", value: "Steaks" },
    { name: "brakes", value: "Brakes" },
    { name: "melee_gamble", value: "RARE! Gamble!" },
    { name: "melee_strike_after_block", value: "Duelist" },
    { name: "fishing_rod_enchant", value: "Club Rod" },
    { name: "water_bucket", value: "Portable Pond" },
    { name: "increase_gold_pickup", value: "Pebble" },
    { name: "xp_per_kill", value: "XP Bump" },
    { name: "blocking_cancels_projectiles", value: "Bullet Time" },
    { name: "rogue", value: "Rogue" },
    { name: "regen_when_hit", value: "Peroxide" },
    { name: "less_damage_when_high_bounty", value: "Billy" },
    { name: "solitude", value: "RARE! Solitude" },
    { name: "melee_combo_damage", value: "Combo: Damage" },
    { name: "etspeed_on_kill", value: "Speedy Kill" },
    { name: "melee_stun", value: "RARE! Combo: Stun" },
    { name: "jump_spammer", value: "Jumpspammer" },
    { name: "explosive_chickens", value: "RARE! Devil Chicks!" },
    { name: "respawn_with_resistance", value: "Respawn: Resistance" },
    { name: "perma_speed", value: "Gotta go fast" },
    { name: "passive_health_regen", value: "Boo-boo" },
    { name: "bow_weakness_on_hit", value: "Wasp" },
    { name: "pin_down", value: "Pin down" },
    { name: "melee_damage_vs_high_hp", value: "King Buster" },
    { name: "melee_launch", value: "RARE! The Punch" },
    { name: "speed_when_hit_few_times", value: "Counter-Offensive" },
    { name: "phoenix", value: "RARE! Phoenix" },
    { name: "combo_xp", value: "Combo: XP" },
    { name: "melee_bleed", value: "RARE! Hemorrhage" },
    { name: "melee_damage_vs_low_hp", value: "Punisher" },
    { name: "melee_healer", value: "RARE! Healer" },
    { name: "tough_crew", value: "Tough Crew" },
    { name: "think_of_the_people", value: "Think of the People" },
    { name: "brakes", value: "Brakes!" },
    { name: "pullbow", value: "RARE! Pullbow" },
    { name: "telebow", value: "RARE! Telebow" },
    { name: "melee_literally_p2w", value: "RARE! Billionaire" },
    { name: "sniper", value: "Sniper" },
    { name: "volley", value: "RARE! Volley" },
    { name: "melee_knockback", value: "RARE! Knockback" },
    { name: "royalty", value: "Royalty" },
    { name: "stereo", value: "RARE! Stereo" },
    { name: "mind_assault", value: "Mind Assault" },
    { name: "melee_crit_midair", value: "Berserker" },
    { name: "less_damage_on_grass", value: "Cricket" },
    { name: "regen_speed_when_low", value: "Revitalize" },
    { name: "gain_arrows_on_hit", value: "Bottomless Quiver" },
    { name: "rod_true_damage", value: "Grandmaster" },
    { name: "first_shot", value: "First Shot" },
    { name: "melee_heal_on_kill", value: "Guts" },
    { name: "melee_avenge", value: "Revengeance" },
    { name: "bow_combo_speed", value: "Faster than their shadow" },
    { name: "bow_spammer", value: "Spammer and Proud" },
    { name: "parasite", value: "Parasite" },
    { name: "bow_slow", value: "Sprint Drain" },
    { name: "melee_damage_vs_bounties", value: "Bounty Reaper" },
    { name: "resistance_on_kill", value: "Counter-Janitor" },
    { name: "regen_when_ooc", value: "RARE! Gomraw's Heart" },
    { name: "wolf_pack", value: "RARE! Wolf Pack" },
    { name: "counter_bounty_hunter", value: "Hunt the Hunter" },
    { name: "higher_max_hp", value: "Hearts" },
    { name: "fishing_rod_kb", value: "Rodback" },
    { name: "melee_lightning", value: "RARE! Combo: Perun's Wrath" },
    { name: "explosive_bow", value: "RARE! Explosive" },
    { name: "damage_per_arrow", value: "Arrow Armory" },
    { name: "escape_pod", value: "RARE! Escape Pod" },
    { name: "less_damage_when_swimming", value: "McSwimmer" },
    { name: "instant_shot", value: "RARE! Mega Longbow" },
    { name: "mixed_combat", value: "Mixed Combat" },
    { name: "arrow_true_damage", value: "Chipping" },
    { name: "melee_speed_on_hit", value: "RARE! Speedy Hit" },
    { name: "superspeed_when_low", value: "Danger Close" },
    { name: "plain_melee_damage", value: "Sharp" },
    { name: "sneak_teleport", value: "RARE! Assassin" },
    { name: "resistance_when_low", value: "Last Stand" },
    { name: "singularity", value: "RARE! Singularity" },
    { name: "refresh_speed_on_kill", value: "Electrolytes" },
    { name: "gold_break_obsidian", value: "Purple Gold" },
    { name: "pit_mba", value: "Pit MBA" },
    { name: "grim_reaper", value: "Grim Reaper" },
    { name: "chance_dont_lose_life", value: "RARE! Divine Miracle" },
    { name: "the_blob", value: "RARE! Pit Blob" },
    { name: "wood_blocks", value: "Creative" },
    { name: "thorns", value: "Prick" },
    { name: "worm", value: "Worm" },
    { name: "power_against_crits", value: "Critically Funky" },
    { name: "lucky_shot", value: "RARE! Lucky Shot" },
    { name: "martyrdom", value: "RARE! Martyrdom" },
    { name: "snowballs", value: "RARE! Snowballs" },
    { name: "punch_once_in_a_while", value: "Push comes to shove" },
    { name: "bow_to_true_damage", value: "RARE! True Shot" },
    { name: "hedge_fund", value: "Hedge Fund" },
    { name: "aegis", value: "Aegis" },
    { name: "heartripper", value: "RARE! Heartripper" },
    { name: "hidden_jewel", value: "Hidden Jewel" },
    { name: "melee_hidden_jewel", value: "Hidden Jewel" },
    { name: "snowmen", value: "RARE! Snowmen Army" },
    { name: "fractional_reserve", value: "Fractional Reserve" },
    { name: "needless_suffering", value: "Needless Suffering" },
    { name: "fishers_unite", value: "Unite" },
    { name: "homing", value: "RARE! Robinhood" },
    { name: "lycanthropy", value: "Lycanthropy" },
    { name: "sanguisuge", value: "Sanguisuge" },
    { name: "trophy", value: "Trophy" },
    { name: "luck_of_the_pond", value: "Luck of the Pond" },
    { name: "nostalgia", value: "RARE! Nostalgia" },
    { name: "golden_handcuffs", value: "RARE! Golden Handcuffs" },
    { name: "guardian", value: "Guardian" },
    { name: "evil_within", value: "Evil Within" },
    { name: "rgm", value: "RARE! Retro-Gravity Microcosm" },
    { name: "tier", value: "Tier" },
    { name: "really_toxic", value: "Really Toxic" },
  ];
  for (let i = 0; i < dictionary.length; i++) {
    if (dictionary[i].value === enchant) {
      return dictionary[i].name;
    }
  }
  return "";
}
</script>

<style>
.v-card {
  margin: 10px;
}

.v-card__text {
  line-height: 0.5rem !important;
}
</style>