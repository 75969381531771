<template>
  <v-container>
    <PlayerComponent />
  </v-container>
</template>

<script>
// @ is an alias to /src
import PlayerComponent from "@/components/PlayerComponent.vue";

export default {
  name: "Player",
  components: {
    PlayerComponent,
  },
};
</script>
