<template>
  <v-container>
    <p style="align: center; color: white; font-size: 12px">
      Players Checked: {{ checked }}
    </p>
    <v-row
      v-if="items.length > 0"
      align="stretch"
      class="d-flex justify-center align-center"
    >
      <v-card
        outlined
        align="left"
        v-for="item in items"
        :key="item._id"
        @click="ownershipHistory(item)"
      >
        <v-card-text>
          <p v-html="translateString(item.name)"></p>
          <p>
            Owner:
            <a
              style="text-decoration: none; color: white"
              :href="'https://pitpanda.rocks/players/' + item.ownerusername"
              target="_blank"
              >{{ item.ownerusername }}</a
            >
          </p>
          <p>Last Seen: {{ translateDate(item.lastsave) }}</p>
          <p
            v-for="(str, index) in item.lore"
            :key="index"
            v-html="translateString(str)"
          ></p>
        </v-card-text>
      </v-card>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialog" scrollable max-width="600px">
        <v-card>
          <v-card-title>Ownership History</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 300px">
            <v-card
              class="mx-auto"
              outlined
              v-for="(iOwner, index) in ownerHistory"
              :key="index"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1">
                    <a
                      :href="'https://namemc.com/profile/' + iOwner.username"
                      target="_blank"
                      style="text-decoration: none; color: white"
                    >
                      {{ iOwner.username }}
                    </a>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                    parseDate(iOwner.time)
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar tile size="40" color="grey">
                  <img
                    :src="'https://crafatar.com/avatars/' + iOwner.uuid"
                    :alt="iOwner.username"
                  />
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="loading" hide-overlay persistent width="400">
        <v-card style="margin: 0px; padding: 9px" color="primary" dark>
          <v-card-text>
            Please stand by
            <p></p>
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog v-model="login" persistent justify="center" width="400">
      <v-card width="380">
        <v-card-title>
          <span class="text-h5">Sharker</span>
        </v-card-title>
        <!-- <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Password*"
                  type="password"
                  v-model="password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="$router.push('/')">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="auth()"> Start </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      You are autistic

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
//import request from 'request';
import fetch from "node-fetch";
const sleep = (waitTimeInMs) =>
  new Promise((resolve) => setTimeout(resolve, waitTimeInMs));
export default {
  data() {
    return {
      checked: 0,
      loading: false,
      dialog: false,
      login: true,
      password: "",
      snackbar: false,
      filters: [
        {
          ench: "",
          value: "",
        },
      ],
      owner: "",
      ownerHistory: [],
      items: [],
      colorCodes: {
        0: "#000000",
        1: "#0000AA",
        2: "#00AA00",
        3: "#00AAAA",
        4: "#AA0000",
        5: "#AA00AA",
        6: "#FFAA00",
        7: "#999999",
        8: "#3f3f3f",
        9: "#5555FF",
        a: "#55FF55",
        b: "#55FFFF",
        c: "#FF5555",
        d: "#FF55FF",
        e: "#FFFF55",
        f: "#FFFFFF",
      },
      enchants: [
        "Regularity",
        "New Deal",
        '"Not" Gladiator',
        "Mirror",
        "RARE! Instaboom",
        "Eggs",
        "RARE! Paparazzi",
        "RARE! Trash Panda",
        "Gold Bump",
        "Moctezuma",
        "RARE! Executioner",
        "Gold Boost",
        "Golden Heart",
        "Self-checkout",
        "RARE! Double-jump",
        "Lodbrok",
        "Beat the Spammers",
        "Sweaty",
        "Lifesteal",
        "XP Boost",
        "Sierra",
        "Pitpocket",
        "Somber",
        "RARE! Combo: Venom",
        "Misery",
        "Spite",
        "Combo: Heal",
        "Combo: Swift",
        "Critically Rich",
        "Diamond Stomp",
        "Fancy Raider",
        "Crush",
        "Bruiser",
        "Pants Radar",
        "Shark",
        "Gold and Boosted",
        "Pain Focus",
        "Grasshopper",
        "Diamond Allergy",
        "Excess",
        "Ring Armor",
        "Strike Gold",
        "TNT",
        "Protection",
        "Negotiator",
        "David and Goliath",
        "Fletching",
        "What doesn't kill you",
        "Respawn: Absorption",
        "Steaks",
        "RARE! Gamble!",
        "Duelist",
        "Club Rod",
        "Portable Pond",
        "Pebble",
        "XP Bump",
        "Bullet Time",
        "Rogue",
        "Peroxide",
        "Billy",
        "RARE! Solitude",
        "Combo: Damage",
        "Speedy Kill",
        "RARE! Combo: Stun",
        "Jumpspammer",
        "RARE! Devil Chicks!",
        "Respawn: Resistance",
        "Gotta go fast",
        "Boo-boo",
        "Wasp",
        "Pin down",
        "King Buster",
        "RARE! The Punch",
        "Counter-Offensive",
        "RARE! Phoenix",
        "Combo: XP",
        "RARE! Hemorrhage",
        "Punisher",
        "RARE! Healer",
        "Tough Crew",
        "RARE! Pullbow",
        "RARE! Telebow",
        "RARE! Billionaire",
        "Sniper",
        "RARE! Volley",
        "RARE! Knockback",
        "Royalty",
        "RARE! Stereo",
        "Mind Assault",
        "Berserker",
        "Cricket",
        "Revitalize",
        "Bottomless Quiver",
        "Grandmaster",
        "First Shot",
        "Guts",
        "Revengeance",
        "Faster than their shadow",
        "Spammer and Proud",
        "Parasite",
        "Sprint Drain",
        "Bounty Reaper",
        "Counter-Janitor",
        "RARE! Gomraw's Heart",
        "RARE! Wolf Pack",
        "Hunt the Hunter",
        "Hearts",
        "Rodback",
        "RARE! Combo: Perun's Wrath",
        "RARE! Explosive",
        "Arrow Armory",
        "RARE! Escape Pod",
        "McSwimmer",
        "RARE! Mega Longbow",
        "Mixed Combat",
        "Chipping",
        "RARE! Speedy Hit",
        "Danger Close",
        "Sharp",
        "RARE! Assassin",
        "Last Stand",
        "RARE! Singularity",
        "Electrolytes",
        "Purple Gold",
        "Pit MBA",
        "Grim Reaper",
        "RARE! Divine Miracle",
        "RARE! Pit Blob",
        "Creative",
        "Prick",
        "Worm",
        "Critically Funky",
        "RARE! Lucky Shot",
        "RARE! Martyrdom",
        "RARE! Snowballs",
        "Push comes to shove",
        "RARE! True Shot",
        "Hedge Fund",
        "Aegis",
        "RARE! Heartripper",
        "Hidden Jewel",
        "Hidden Jewel",
        "RARE! Snowmen Army",
        "Fractional Reserve",
        "Needless Suffering",
        "Unite",
        "RARE! Robinhood",
        "Lycanthropy",
        "Sanguisuge",
        "Trophy",
        "Luck of the Pond",
        "RARE! Nostalgia",
        "RARE! Golden Handcuffs",
        "Guardian",
        "Evil Within",
        "RARE! Retro-Gravity Microcosm",
        "Tier",
        "Really Toxic",
      ],
    };
  },
  methods: {
    auth: async function () {
      /* const response = await fetch("https://omi.systems/auth/" + this.password);
      const json = await response.json();
      if (json.auth == true) {
        this.login = false;
        this.startChecking();
      } else {
        this.password = "";
      } */
      this.login = false;
      this.startChecking();
    },
    translateString: function (str) {
      if (str.length == 0) {
        return "";
      }
      let responseString = "";
      let firstColor = true;
      let nextColor = false;
      for (let letter of str) {
        //console.log(letter)
        if (letter === "§") {
          nextColor = true;
          continue;
        }
        if (nextColor) {
          nextColor = false;
          if (!firstColor) {
            responseString += "</span>";
          } else {
            firstColor = false;
          }

          responseString +=
            '<span style="color: ' + this.colorCodes[letter] + ';">';
          continue;
        }
        responseString += letter;
      }
      responseString += "</span>";

      return responseString;
    },
    translateDate: function (timestamp) {
      var a = new Date(timestamp * 1000);
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours();
      var min = a.getMinutes();

      var time =
        date +
        " " +
        month +
        " " +
        year +
        " " +
        hour.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        ":" +
        min.toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
      return time;
    },
    ownershipHistory: async function (item) {
      if (item.nonce == 9 || item.nonce == 6) return;
      this.loading = true;
      const response = await fetch("https://omi.systems/owner/" + item.nonce);
      const json = await response.json();
      console.log(json);

      this.ownerHistory = json.reverse();

      this.loading = false;
      this.dialog = true;
    },
    parseDate: function (str) {
      return new Date(str).toLocaleDateString();
    },
    startChecking: async function () {
      console.log("Checker Started");
      const response = await fetch(
        "https://omi.systems/search/melee_heal_on_hit0+,tier2"
      );

      const t2s = await response.json();

      for (let i = 0; i < t2s.length; i++) {
        this.checked++;
        if (await online(t2s[i].ownerusername)) {
          /* console.log(
          t2s[i].ownerusername +
            " is online! Number of Enchants: " +
            t2s[i].enchpit.length
        ); */
          if(t2s[i].enchpit.length <=2) {
            this.items.push(t2s[i]);
          }
          
        }
        await sleep(900);
      }
    },
  },
};
async function online(ign) {
  let response, data;
  try {
    response = await fetch("https://api.slothpixel.me/api/players/" + ign);
    data = await response.json();
  } catch (e) {
    return false;
  }
  

  if (data.online != null) return data.online;
  return false;
}
</script>

<style>
.v-card {
  margin: 10px;
}
.v-card__text {
  line-height: 0.5rem !important;
}
</style>