<template>
  <v-app-bar app flat>
    <v-tabs centered class="ml-n9" color="grey darken-1">
      <v-tab v-for="link in links" :key="link.name" :to="link.link">
        {{ link.name }}
      </v-tab>
    </v-tabs>
    <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"><img
      src="https://crafatar.com/avatars/9c47c85f1bbf4d67897cd6dcee72ff81"
      alt="Omi says hi"
      width="40"
      tooltip="Omi says hi"
    /></a>
  </v-app-bar>
</template>

<script>
export default {
  name: "Header",
  data: () => ({
    links: [
      {
        name: "SEARCH",
        link: "/",
      },
      /* {
          name: 'Grinders',
          link: '/grinders'
        }, */
      {
        name: "STATS",
        link: "/about",
      },
      {
        name: "ITEM FINDER",
        link: "/shark"
      },
      {
        name: "PLAYER",
        link: "/player"
      }
    ],
  }),
};
</script>