<template>
  <v-container>
    <SharkComponent />
  </v-container>
</template>

<script>
// @ is an alias to /src
import SharkComponent from "@/components/SharkComponent.vue";

export default {
  name: "Shark",
  components: {
    SharkComponent,
  },
};
</script>
